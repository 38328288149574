import { FC, useState, useEffect, useCallback, SyntheticEvent, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Table } from '@consta/uikit/Table';
import { Pagination } from '@consta/uikit/Pagination';
import { AutoComplete } from '@consta/uikit/AutoCompleteCanary';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/Button';
import { Combobox } from '@consta/uikit/Combobox';
import { IconSearchStroked } from '@consta/icons/IconSearchStroked';

import { ModalCreateUser } from 'features/UsersFeatures/ModalCreateUser/ModalCreateUser';
import { itemsSelectRole } from 'shared/constants/itemsSelectRole';
import { itemsSelectStatus } from 'shared/constants/itemsSelectStatus';
import { itemsAutoCompleteMunicipality } from 'shared/constants/itemsAutoCompleteMunicipality';
import { FilteringOptionsForUsers } from 'shared/types/filteringOptionsForUsers';
import { User } from 'shared/types/user';

import activity from '../../../assets/icons/activity.svg';
import subtract from '../../../assets/icons/subtract.svg';

import styles from './Users.module.css';
import {
  filterParamsUserReq,
  getParamsFromUrlForUsers,
  getUsersNavigateUrl,
  structureUser,
} from '../../../shared/utils/helper';
import { getListOfUsers } from '../../../shared/Api/users';



export const Users: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [listOfUsers, setListOfUsers] = useState<User[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterParams, setFilterParams] = useState(getParamsFromUrlForUsers(queryParams));

  useEffect(() => {
    const fetchUsers = async () => {
      setListOfUsers([]);
      const params = filterParamsUserReq({
        ...filterParams,
        status: filterParams?.status?.statusFilter ?? null,
        role: filterParams?.role?.value ?? null,
      });

      const offset = (page - 1) * limit;
      try {
        const response = await getListOfUsers({ ...params, limit, offset });
        if (response?.data) {
          setListOfUsers(structureUser(response.data, offset).map((user, index) => ({
            ...user,
            id: Number(user.id),
            serial: offset + index + 1,
          })));
          setTotal(response.total || 0);
        }
      } catch (error) {
        console.error('Ошибка при загрузке пользователей:', error);
      }
    };

    fetchUsers();
  }, [filterParams, page, limit]);

  const sortedUsers = useMemo(() => {
    return [...listOfUsers].sort((a, b) => {
      const nameA = a.fullName.trim().toLowerCase();
      const nameB = b.fullName.trim().toLowerCase();
      return nameA.localeCompare(nameB, 'ru');
    });
  }, [listOfUsers]);
  


  const handleFilterChange = useCallback((value: any, field: string) => {
    const updatedFilters = { ...filterParams, [field]: value };
    setFilterParams(updatedFilters);
    sessionStorage.setItem('userFilters', JSON.stringify(updatedFilters));
    navigate(getUsersNavigateUrl(updatedFilters), { replace: true });
  }, [filterParams, navigate]);

  const handleRowClick = useCallback(({ id, e }: { id: string | undefined; e?: SyntheticEvent }) => {
    if (!id) return console.error('Ошибка: ID отсутствует!');
    if (e?.target instanceof HTMLImageElement) {
      const user = listOfUsers.find((user) => String(user.id) === id);
      if (user) navigate(`/activity?members[]=${user.id}`);
    } else {
      navigate(`/users/${id}`);
    }
  }, [listOfUsers, navigate]);

  return (
    <div className={styles.wrapper}>
      <ModalCreateUser isOpened={isModalOpen} onToggle={setIsModalOpen} />
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Список пользователей</h3>
        <div className={styles.buttonIconFlex}>
          <a href={`/api/clients/export-by-filter?${queryParams}`} download>
            <img src={subtract} alt="Export" />
          </a>
          <Button label="Создать пользователя" style={{ width: '227px' }} onClick={() => setIsModalOpen(!isModalOpen)} />
        </div>
      </div>
      <div className={styles.autoCompleteContainer}>
        <TextField onChange={(e) => handleFilterChange(e.value, 'search')} value={filterParams.search} placeholder="Введите ФИО, ID или email" leftSide={IconSearchStroked} style={{ minWidth: '471px' }} />
        <AutoComplete placeholder="МО" value={filterParams.municipality} items={itemsAutoCompleteMunicipality} onChange={(e) => handleFilterChange(e.value, 'municipality')} width="full" />
        <Combobox placeholder="Роль" items={itemsSelectRole} value={filterParams.role} onChange={(e) => handleFilterChange(e.value, 'role')} />
        <Combobox placeholder="Статус" items={itemsSelectStatus} value={filterParams.status} onChange={(e) => handleFilterChange(e.value, 'status')} />
      </div>
      <Table
        rows={sortedUsers.map((user) => ({
          ...user,
          id: String(user.id),
          rowKey: String(user.id),
        }))}
        columns={[
          { title: '№', accessor: 'serial', width: 50 },
          { title: 'ФИО', accessor: 'fullName', width: 400, sortable: true },
          { title: 'ID', accessor: 'id', width: 100 },
          {
            title: 'Статус', accessor: 'status', width: 120, renderCell: (row) => (
              <div className={`${row.status === 'Новый' ? styles.newStatus : row.status === 'Активный' ? styles.activeStatus : styles.blockedStatus}`}>{row.status}</div>
            )
          },
          { title: 'Email', accessor: 'email' },
          { title: 'Роль', accessor: 'role' },
          {
            title: 'Дата создания', accessor: 'updatedAt', width: 200, renderCell: (row) => (
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>15.02.2023</span>
                <img src={activity} alt="activity" style={{ width: '50px', height: '20px' }} />
              </div>
            )
          },]}
        zebraStriped="even"
        borderBetweenRows
        borderBetweenColumns
        activeRow={{ id: undefined, onChange: handleRowClick }}
      />
      <div className={styles.pagination}>
        <Pagination totalPages={Math.ceil(total / limit) || 1} currentPage={page - 1} onChange={(p) => setPage(p + 1)} />
      </div>
    </div>
  );
};